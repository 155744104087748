.application-head {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.modal-dialog {
  padding: 12px;
}
.tab-pane,
.app-nav-tabs .nav-link.active {
  border: 1px solid #e0e0e0;
}
.app-nav-tabs .nav-link.active {
  border-bottom: 0;
}
/* ------------------------------------ */

.mt-100 {
  margin-top: 200px;
}
.progress {
  width: 32px;
  height: 32px !important;
  float: left;
  line-height: 20px;
  background: none;
  /* margin: 20px; */
  box-shadow: none;
  position: relative;
}
.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}
.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progress .progress-left {
  left: 0;
}
.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 4px;
  border-style: solid;
  position: absolute;
  top: 0;
}
.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress .progress-right {
  right: 0;
}
.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}
.progress .progress-value {
  width: 50%;
  height: 50%;
  border-radius: 50%;
  /* background: #CB874E; */
  font-size: 10px;
  color: #cb874e;
  /* line-height: 135px; */
  text-align: center;
  position: absolute;
  top: 20%;
  left: 25%;
}
.progress.blue .progress-bar {
  border-color: #049dff;
}
.progress.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}
.progress.yellow .progress-bar {
  border-color: #fdba04;
}
.progress.yellow .progress-right .progress-bar {
  animation: loading-3 1.8s linear forwards;
}
.progress.yellow .progress-left .progress-bar {
  animation: none;
}
@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}
@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

.basic-info {
  border-bottom: 0.5px solid #e0e0e0;
  margin-bottom: 0px;
}
.info-toggle-wrap {
  margin-top: 16px;
}
.info-block label {
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  /* Grey 1 */
  color: #909090;
}
.info-block button {
  border: none;
  background-color: transparent;
  margin-left: 4px;
}
.basic-info span {
  font-family: "OpenSans-Regular";
  font-size: 14px;
  line-height: 19px;
  color: #303030;
  word-break: break-all;
}
.info-toggle {
  margin-bottom: 10px;
  background: #ffffff;
  /* Stroke */
  border: 0.5px solid #e0e0e0;
  border-radius: 8px;
}
.status-block img {
  margin: 0 5px;
}
.view-b label {
  font-size: 12px;
  line-height: 16px;
  color: #2dc3e8;
}
.status-block span {
  background: #e6fdf0;
  border-radius: 4px;
  padding: 6px;
}
.toggle-heads,
.cust-coll-appl {
  /* padding: 10px 25px; */
}
.toggle-heads button {
  border: none;
  background-color: transparent;
  font-size: 12px;
  line-height: 16px;
  font-family: "OpenSans-Bold";
  color: #2dc3e8;
}
.toggle-heads h6 {
  font-size: 16px;
  /* line-height: 16px; */
  /* Grey 3 */
  color: #303030;
  font-family: "OpenSans-SemiBold.ttf";
  padding: 12px 15px;
}
.toggle-heads b{
  font-size: 13px;
  /* font-family: "OpenSans-SemiBold.ttf"; */
}
.sub-text{
  font-size: 10px;
  /* font-family: "OpenSans-SemiBold.ttf"; */
}
/* .cust-coll-appl {
  padding: 10px 20px;
} */
.cust-card-appl {
  border-top: 0.5px solid #e0e0e0;
  border-radius: 0;
  padding-top: 0;
}
.block label {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  /* Grey 3 */
  color: #909090;
}
.block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.brwn-block {
  background: #f8ece2;
  /* In Progress */
  border: 1px solid #cb874e;
  border-radius: 4px;
}
.green-block-line {
  /* background: #f8ece2; */
  /* In Progress */
  border: 1px solid #46a96f;
  border-radius: 4px;
  padding-bottom: 10px;
}
.red-block-line {
  /* background: #f8ece2; */
  /* In Progress */
  border: 1px solid #ff4848;
  border-radius: 4px;
}
.brwn-block-line {
  /* In Progress */
  border: 1px solid #cb874e;
  border-radius: 4px;
  padding-bottom: 5px;
}
.block.brwn-block label,
.block.green-block label {
  font-size: 12px;
  line-height: 16px;
  color: #303030;
  font-family: "OpenSans-Regular";
}
.block.brwn-block span {
  font-size: 14px;
  color: #cb874e;
  font-family: "OpenSans-SemiBold.ttf";
}
.block.brwn-block img {
  width: 17px;
  height: 16px;
}
.block .green-stat {
  font-size: 12px;
  color: #46a96f;
  font-family: "OpenSans-SemiBold.ttf";
}
.block .red-stat {
  font-size: 12px;
  color: #ff4848;
  font-family: "OpenSans-SemiBold.ttf";
}
.block .brown-stat {
  font-size: 12px;
  color: #cb874e;
  font-family: "OpenSans-SemiBold.ttf";
}
.notify-wrap {
  /* margin-top: 20px; */
}
.error-msg {
  font-family: "OpenSans-Regular";
  font-size: 12px;
  line-height: 16px;
  color: #f9bb04;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.error-msg img {
  margin-right: 8px;
}
.docs-img,
.doc-subwrap {
  /* position: relative; */
  margin-left: 7px;
}

.doc-row{
  display: flex;
  flex-wrap: wrap;
}
.notify-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 25px; */
  margin-bottom: 10px;
}
.notify-head h4{
  font-size: 12px;
  color: #000000;
  font-family: "OpenSans-SemiBold.ttf";;
}
.notify-head .red-btn{
  color:red;
}
.doc-subwrap {
  margin-left: -10px;
  margin-right: -10px;
}
.docs-img{
  margin: 0px;
  padding: 0px 10px;
}


.docs-img,
.docs-img img,
.docs-upload {
  /* width: 100%;
  max-width: 150px;
  height: 80px;
  position: relative !important; */
  width: 100%;
  max-width: 170px;
  /* min-width: 90px; */
  height: 80px;
  position: relative !important;
  /* padding: 0px 10px; */
  object-fit: cover;
}
.doc-row .docs-img img,
.docs-img img{
  padding: 0px;
}
.doc-subwrap .notify-span {
  position: absolute;
  transform: translate(0px, 30px);
  left: 40%;
}
.doc-subwrap .notify-span img {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #f9bb04;
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 20px;
  width: 32px;
  height: 32px;
  padding: 5px;
  right: 0;
}
.docs-wrap {
  margin-top: 30px;
}
.docs-img .delete-btn {
  position: absolute;
  right: 9px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */
  border: none;
  border-radius: 4px 0px;
  padding: 1px;
  cursor: pointer;
}
.docs-img .delete-btn img {
  width: 20px;
  height: 20px;
  position: static !important;
}
.doc-subwrap .notify-span.green-notf img {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #46a96f;
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 20px;
  width: 32px;
  height: 32px;
  padding: 5px;
  right: 0;
}
.appl-name {
  margin-bottom: 12px;
}
.appl-name h6 {
  font-size: 12px;
  line-height: 16px;
  font-family: "OpenSans-Regular";
  color: #303030;
}
.green-block {
  background: #e6fdf0 !important;
  /* In Progress */
  border: 1px solid #46a96f;
  border-radius: 4px;
}
.block.green-block span {
  font-size: 14px;
  color: #46a96f;
  font-family: "OpenSans-SemiBold.ttf";
}
.block.green-block img {
  width: 17px;
  height: 16px;
}
.upload-subwrap > input {
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.docs-upload {
  cursor: pointer;
  border: 1px dashed #e0e0e0;
  border-radius: 4px;
  text-align: center;
}
.docs-upload p {
  font-size: 8px;
  line-height: 11px;
  color: #909090;
}
.docs-upload .blue-up {
  font-size: 12px;
  line-height: 16px;

  /* Primary CTA */

  color: #2dc3e8;
}
.block p span {
  font-size: 10px;
  color: #909090;
  color: #909090;
}
.emp-dets {
  margin-top: 13px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 30px;
}
/* .emp-inputs label {
  width: 100%;
  font-size: 11px;
  line-height: 19px;
  width: 100%;
  margin-bottom: 8px;
  font-family: "OpenSans-SemiBold.ttf";
  color: #606060;
} */
.emp-inputs input,
.emp-inputs select {
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  /* max-width: 320px; */
  width: 100%;
  font-family: "OpenSans-Regular";
  margin-bottom: 8px;
  font-size: 11px;
  line-height: 19px;
  color: #303030;
  padding: 8px;
  margin-bottom: 0;
}
.add-ip {
  max-width: 100% !important;
}
.emp-inputs {
  margin-bottom: 14px;
}

.datepicker-toggle {
  display: inline-block;
  position: relative;
  /* width: 18px;
  height: 19px; */
}
.datepicker-toggle-button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/calender.svg");
  background-repeat: no-repeat;
  background-position: 99% 50%;
}
.datepicker-toggle input[type="date"] {
  /* opacity: 0; */
}
.emp-paystb-btn {
  border: none;
  background-color: transparent;
  background: #ffffff;
  /* Primary CTA */
  border: 0.5px solid #2dc3e8;
  border-radius: 4px;
  padding: 10px 24px;
  white-space: nowrap;
  text-align: center;
}
.emp-paystb-btn span {
  font-size: 14px;
  line-height: 19px;
  color: #2dc3e8;
}
.emp-paystb-btn svg {
  margin-right: 12px;
}

.patstb-flex {
  display: flex;
  padding: 30px;
}
/* .main_div_text, .main_div_image {
  max-width: 50%;
  width: 100%;
  flex: 50%;
} */
.main_div_image {
  /* max-width: 300px;
  width: 100%;
  flex: 1 1 300px; */
  flex: 0 0 500px;
  max-width: 500px;
  width: 100%;
}
/* #nav-patstub1,
.patstb-flex .emp-inputs input,
.emp-inputs select {
  background: #f5fdff;
} */
.emp-inputs span {
  color: #ff4848;
}
.main_div_image {
  background: #d8d6d6;
  border-radius: 8px;
  padding: 30px;
}
.img-dwd {
  display: flex;
  margin-bottom: 10px;
}
.img-dwd button {
  border: 0.5px solid #2dc3e8;
  filter: drop-shadow(4px 8px 20px rgba(45, 195, 232, 0.2));
  border-radius: 4px;
  background-color: transparent;
  margin-left: auto;
  width: 40px;
  height: 40px;
}
.img-dwd h6 {
  font-size: 14px;
  line-height: 19px;
  color: #909090;
}
.patstb-flex .emp-inputs input:last-child {
  margin-bottom: 0;
}
.doc-up-tabs button:hover {
  color: #303030;
  /* color: #303030;
  font-family: "OpenSans-Bold"; */
}
.doc-up-tabs button {
  font-size: 14px;
  line-height: 19px;
  color: #909090;
  background-color: #fff !important;
}
.doc-up-tabs button.active {
  font-size: 14px;
  line-height: 19px;
  color: #303030;
  font-family: "OpenSans-Bold";
  background: #f5fdff !important;
  border: 0;
}
.nav-tabs .nav-link {
  margin-bottom: -10px;
  border: 0;
  /* width: 100%; */
  /* max-width: 50%;
  flex: 0 0 50%;
  text-align: center; */
}
.name1 {
  display: flex;
  align-items: center;
}
.name1-wrap {
  background: #f8f8f8;
  border-radius: 8px 8px 8px 8px;
}
.app-nav-tabs .name1 h6 {
  font-size: 12px;
  line-height: 19px;
  color: #606060;
  font-family: "OpenSans-Regular";
  margin-right: 8px;
  word-break: break-word;
  white-space: normal;
}
.app-nav-tabs .nav-link.active {
  background: #f8f8f8;
  border-radius: 8px 8px 0px 0px;
  /* border: 1px solid #E0E0E0; */
  border-color: #e0e0e0 #e0e0e0 #e0e0e0;
}
.app-nav-tabs .nav-link.active .name1 h6 {
  font-size: 12px;
  line-height: 19px;
  /* identical to box height */
  text-align: center;
  /* Grey 3 */
  font-family: "OpenSans-Bold";
  color: #303030;
}
.app-nav-tabs {
  border-bottom: none;
}
.application-tabs nav {
  margin-bottom: -2px;
}
.app-nav-tabcontent .tab-pane {
  padding: 20px 16px 0 16px;
  margin-top: 11px;
}
.app-nav-tabcontent #nav-patstub1,
.app-nav-tabcontent #nav-patstub2 {
  border: 0;
  margin-top: -7px;
}
.info-block .view-b img {
  width: 100%;
  max-width: 12px;
  height: 13px;
}
.md-btns {
  font-size: 14px;
  font-family: "OpenSans-Regular";
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  max-width: 140px;
  width: 100%;
  height: 40px;
  margin-right: 4px;
  color: #909090;
}
.md-btns.active {
  color: #2dc3e8;
  background: #ecfbff;
  /* Primary CTA */
  border: 1px solid #2dc3e8;
  border-radius: 4px;
  font-family: "OpenSans-Bold";
}

.modal-dialog.modal-dialog-centered.modal-lg {
  max-width: 964px;
  width: 100%;
}
.modal-dialog.modal-dialog-centered.modal-lg .emp-inputs input,
.emp-inputs select {
  max-width: 430px;
  width: 100%;
}
.emp-inputs .new-sel {
  /* max-width: 320px; */
}
.cancelbtn {
  border: 0.5px solid #2dc3e8;
  filter: drop-shadow(4px 8px 20px rgba(45, 195, 232, 0.2));
  border-radius: 4px;
  color: #2dc3e8;
  font-family: "OpenSans-Bold";
  padding: 10px 24px;
  background-color: #fff;
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.cancelbtn svg {
  margin-right: 13px;
}
.modal-blue-btn {
  border: 0;
  background: #2dc3e8;
  box-shadow: 4px 8px 20px rgba(45, 195, 232, 0.2);
  border-radius: 4px;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  padding: 13px 24px;
  display: flex;
  align-items: center;
}
.modal-blue-btn svg {
  margin-right: 13px;
}
.modal-header {
  border-bottom: 0;
}
.modal-footer {
  border-top: 0;
}
.application-head .modal-sm button {
  border: 0;
  background-color: transparent;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height */

  font-family: "OpenSans-Bold";
  /* Primary CTA */
  display: flex;
  align-items: center;

  color: #2dc3e8;
}
.application-head .modal-sm button img {
  margin-right: 4px;
}
.application-head .modal-sm button span {
  font-size: 12px;
  line-height: 19px;
  font-family: "OpenSans-Bold";
}

/*  */
.selector {
  position: relative;
  width: 60%;
  display: flex;
  align-items: center;
}
.selecotr-item {
  position: relative;
  /* flex-basis: calc(70% / 3);
  height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
}
.selector-item_radio {
  appearance: none;
  display: none;
}
.selector-item_label {
  position: relative;
  /* height: 80%;
  width: 100%; */
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.5s;
  transition-property: transform, color, box-shadow;
  transform: none;
  padding: 0 12px;
  /* width: 150px; */
  text-transform: uppercase;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  font-family: "OpenSans-Regular";
  color: #909090;
}
.selector-item_radio:checked + .selector-item_label {
  background-color: var(--blue);
  color: var(--white);
  /* transform: translateY(-2px); */
  background: #ecfbff;
  /* Primary CTA */
  border: 1px solid #2dc3e8;
  border-radius: 4px;

  /* Primary CTA */

  color: #2dc3e8;
}

.modal-header h5 {
  font-size: 18px;
  line-height: 25px;
  color: #303030;
  font-family: "OpenSans-SemiBold.ttf";
}
.red-hd {
  font-size: 18px;
  line-height: 25px;
  color: #ff4848 !important;
  font-family: "OpenSans-Bold" !important;
}
.modal-content {
  background: #ffffff;
  border-radius: 20px;
}
.archieve-md {
  max-width: 600px;
  width: 100%;
}
.archieve-md .modal-body {
  padding: 0 1rem;
}
.tr-lable {
  display: contents;
}
.expand-heads {
  padding: 10px 15px;
}
.expand-heads button {
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 16px;
  font-family: "OpenSans-Bold";
  color: #2dc3e8;
}
.subheaders h6 {
  font-size: 12px;
  line-height: 16px;
  font-family: "OpenSans-Bold";
}
.subheaders {
  padding: 5px 0 16px 0;
}
.prf-upload {
  font-size: 12px;
  line-height: 16px;
  font-family: "OpenSans-Regular";
  color: black;
}
.prf-flex-block {
  justify-content: space-between;
  align-items: center;
}
.month-inc h6 {
  font-size: 12px;
  line-height: 16px;
  color: #909090;
  justify-content: center;
}
.month-inc span {
  color: #303030;
  font-size: 12px;
  line-height: 16px;
}
.income-wrap {
  justify-content: space-between;
}
.sub-hd-flex {
  padding: 0px 15px 0 0px;
}
.nav-flexes {
  display: flex;
  justify-content: space-between;
}

/* ----------------- */
.toggleWrapper {
  position: relative;
  /* background: #eeeeee; */
  /* Stroke */
  width: 130px;
  /* border: 1px solid #e0e0e0; */
  border-radius: 20px;
}
.toggleWrapper input {
  position: absolute;
  left: -99em;
}

.toggle {
  cursor: pointer;
  display: inline-block;
  /* position: relative; */
  width: 48px;
  height: 26px;
  background: #eeeeee;
  /* Stroke */

  border: 1px solid #e0e0e0;
  border-radius: 20px;
  transition: 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.toggle::before {
  content: "All Details";
  position: absolute;
  left: -70px;
  top: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #2dc3e8;
  font-family: "OpenSans-Bold";
}
.toggle::after {
  content: "Doc Details";
  position: absolute;
  right: 3px;
  top: 05px;
  font-size: 12px;
  line-height: 16px;
  color: #909090;
  font-family: "OpenSans-Bold";
}

.toggle__handler {
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 2px;
  left: 3px;
  width: 20px;
  height: 20px;
  background-color: #ffcf96;
  border-radius: 50px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform: rotate(-45deg);
}

input:checked + .toggle {
  background: #eeeeee;
}
input:checked + .toggle::before {
  color: #909090;
}
input:checked + .toggle::after {
  color: #2dc3e8;
}
.toggle__handler {
  background: #2dc3e8;
  border-radius: 20px;
  /* transform: translate3d(40px, 0, 0) rotate(0); */
}
.crater {
  opacity: 1;
}
input:checked + .toggle .toggle__handler {
  background: #2dc3e8;
  transform: translate3d(25px, 0, 0) rotate(0);
}
.block img {
  margin: 10px 0 3px 0;
}
.view-b {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
}

.modal-table thead tr th {
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #909090;
  font-family: "OpenSans-Regular";
  padding: 10px 20px;
}
.modal-table tbody tr td {
  font-size: 12px;
  line-height: 16px;
  color: #303030;
  font-family: "OpenSans-Regular";
  padding: 10px;
  text-align: center;
}
.tb-green-block {
  background: #e6fdf0;
  border-radius: 4px;
  padding: 10px 20px;
  top: 10px;
  position: relative;
  font-size: 14px;
  color: #46a96f;
  font-family: "OpenSans-SemiBold.ttf";
  padding: 10px;
}
.tb-green-block span {
}

.tb-grey-block {
  background: #e9e9e9 !important;
  border-radius: 4px;
  padding: 10px 20px;
  position: relative;
  top: 6px;
  font-size: 14px;
  color: #909090;
  font-family: "OpenSans-SemiBold.ttf";
}
.tb-grey-block span {
}

.tb-red-block {
  /* background: #ffeeee !important;
  border-radius: 4px;
  padding: 10px 20px;
  position: relative;
  top: 6px;
  font-size: 14px;
  color: #ff4848 !important;
  font-family: "OpenSans-SemiBold.ttf"; */

  background: #e6fdf0;
  border-radius: 4px;
  padding: 10px 20px;
  top: 10px;
  position: relative;
  font-size: 14px;
  color: #46a96f;
  font-family: "OpenSans-SemiBold.ttf";
  padding: 10px;
}
.tb-red-block span {
}
.table-msg-btn {
  display: block;
  position: relative;
  top: 6px;
}
.modal-table tbody tr:nth-child(odd) {
  background: #f9f9f9;
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 30%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}
.off {
  color: white;
  position: absolute;
  transform: translate(30%, -50%);
  top: 50%;
  left: 30%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider .on {
  display: block;
  color: #606060;
}

input:checked + .slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  /* border-radius: 34px; */
}
/* 
.slider.round:before {
  border-radius: 50%;
} */

/* --------------------------------------------- */
span.switcher {
  position: relative;
  top: 2px;
  /* width: 200px;
  height: 50px;
  border-radius: 25px;
  margin: 20px 0; */
}

span.switcher.switcher-1 input,
span.switcher.switcher-2 input,
span.switcher.switcher-3 input {
  transition: 0.25s -0.1s;
}

span.switcher input:before {
  background-image: url(../images/unchecked.svg);
  background-repeat: no-repeat;
  background-position: 99% 50%;
  content: "";
  left: 0px;
  width: 25px;
  height: 25px;
}
span.switcher input:before {
  left: 10%;
}
span.switcher input::after {
  right: 0;
}
span.switcher input:before,
span.switcher input:after {
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #909090;
}
span.switcher.switcher-1 input:checked:after,
span.switcher.switcher-2 input:checked:after,
span.switcher.switcher-3 input:checked:after {
  color: #213d93;
  transition: color 0.5s;
}
span.switcher.switcher-1 input:checked::before,
span.switcher.switcher-2 input:checked::before,
span.switcher.switcher-3 input:checked::before {
  color: #909090;
  transition: color 0.5s;
}
span.switcher input:after {
  content: " Active";
  right: 40px;
  color: #46a96f;
  width: 60px;
  right: 30px;
}

span.switcher.switcher-1 input:checked + label,
span.switcher.switcher-2 input:checked + label,
span.switcher.switcher-3 input:checked + label {
  left: 10px;
  right: 100px;
  background: #1e1e1e;
  transition: left 0.5s, right 0.4s 0.2s;

  background: #eeeeee;
  /* Grey 1 */

  border: 0.5px solid #909090;
  box-shadow: 4px 8px 20px rgba(144, 144, 144, 0.2);
  border-radius: 3px;
}
span.switcher label {
  z-index: 1;
  position: absolute;
  top: -20px;
  bottom: 10px;
  border-radius: 20px;
  width: 100px;
  transition: left 0.5s, right 0.4s 0.2s;
}

span.switcher input {
  appearance: none;
  position: relative;
  width: 162px;
  height: 36px;
  border-radius: 25px;
  background: #eeeeee;
  border-radius: 4px;
  outline: none;
}
span.switcher input :before,
span.switcher input :after {
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

span.switcher input label {
  z-index: 1;
  position: absolute;
  top: 10px;
  bottom: 10px;

  border-radius: 20px;
}
.switcher-1 input,
.switcher-2 input,
.switcher-3 input {
  transition: 0.25s -0.1s;
}

.switcher-1 input:checked::after label,
.switcher-2 input:checked::after label,
.switcher-3 input:checked::after label {
  left: 100px;
  right: 10px;
  background: pink;
  transition: left 0.4s 0.2s, right 0.5s;
}

span.switcher.switcher-1 input:not(:checked) + label,
span.switcher.switcher-2 input:not(:checked) + label,
span.switcher.switcher-3 input:not(:checked) + label {
  /* left: 30px; */
  right: 5px;
  background: #ebfff3;
  /* Success */
  border: 0.5px solid #46a96f;
  box-shadow: 4px 8px 20px rgba(70, 169, 111, 0.1);
  border-radius: 3px;
  transition: left 0.4s 0.2s, right 0.5s;
  animation: turn-off 0.5s ease-out;
}
@keyframes turn-off {
  0% {
    right: 100%;
  }
  100% {
    right: 0%;
  }
}

.table-msg-btn img {
  display: block;
  margin: auto;
}

span.switcher.switcher-1 input:checked:after,
span.switcher.switcher-2 input:checked:after,
span.switcher.switcher-3 input:checked:after {
  background-image: url(../images/checked.svg);
  background-repeat: no-repeat;
  background-position: 99% 50%;
  content: "";
}
span.switcher input:checked:before {
  content: "Inactive";
  background-image: url(../images/unchecked.svg);
  background-repeat: no-repeat;
  background-position: 99% 50%;
  width: 85px;
  height: 15px;
  text-align: end;
  position: absolute;
  top: 18px;
  font-family: "OpenSans-Bold";
  color: #606060 !important;
}

span.switcher input:checked:after {
  /* content: "Aactive"; */
  background-image: url(../images/checked.svg);
  background-repeat: no-repeat;
  background-position: 99% 50%;
  width: 25px;
  height: 25px;
  color: #46a96f;
}
span.switcher.switcher-1 input::before,
span.switcher.switcher-2 input::before,
span.switcher.switcher-3 input::before {
  /* background-image: url(../images/checked.svg); */
  background-repeat: no-repeat;
  background-position: 9% 50%;
}

span.switcher.switcher-1 input::after,
span.switcher.switcher-2 input::after,
span.switcher.switcher-3 input::after {
  background-image: url(../images/checked.svg);
  background-repeat: no-repeat;
  background-position: 99% 50%;
  right: 20px;
}
.selector.modal-selector {
  width: auto;
}
.modal-predf.cust-md-predmsg {
  max-width: 100%;
  width: 100%;
  margin-top: 20px;
}

.cus-select-status{
  background: transparent;
  border: 0px;
  font-family: 'OpenSans-SemiBold.ttf';
  font-size: 12px;
  width: 100%;
  line-height: 20px;
  height: 20px;
}

.modal-predf .emp-inputs {
  margin-bottom: 30px;
}
.cust-share-modal {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.cust-share-modal button span {
  font-size: 14px;
  line-height: 19px;
}
.bodyhd h6 {
  font-size: 12px;
  line-height: 16px;
  color: #606060;
  font-family: "OpenSans-SemiBold.ttf";
}

.md-predmsg {
  position: relative;
}
.md-predmsg span {
  position: absolute;
  left: 50%;
}
.table-msg-btn button {
  background-color: transparent;
  border: none;
}

.progress-gr {
  width: 32px;
  height: 32px !important;
  float: left;
  line-height: 20px;
  background: none;
  /* margin: 20px; */
  box-shadow: none;
  position: relative;
}
.progress-gr:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}
.progress-gr > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progress-gr .progress-left {
  left: 0;
}
.progress-gr .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 4px;
  border-style: solid;
  position: absolute;
  top: 0;
}
.progress-gr .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress-gr .progress-right {
  right: 0;
}
.progress-gr .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}
.progress-gr .progress-value {
  width: 50%;
  height: 50%;
  border-radius: 50%;
  /* background: #CB874E; */
  font-size: 10px;
  color: #cb874e;
  /* line-height: 135px; */
  text-align: center;
  position: absolute;
  top: 20%;
  left: 25%;
}
.progress-gr.blue .progress-bar {
  border-color: #049dff;
}
.progress-gr.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}
.progress-gr.yellow .progress-bar {
  border-color: #fdba04;
}
.progress-gr.yellow .progress-right .progress-bar {
  animation: loading-green 1.8s linear forwards;
}
.progress-gr.yellow .progress-left .progress-bar {
  animation: none;
}
@keyframes loading-green {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.status-block span h6 {
  font-size: 12px;
  line-height: 16px;
  color: #46a96f;
  margin-left: 6px;
}
/* -------------------- */
.tooltip-wrapper {
  z-index: 99;
  text-transform: uppercase;
  color: #555;
  position: relative;
  text-align: left;
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

.tooltip-wrapper .tooltip {
  background: #ffffff;
  display: block;
  margin-bottom: 15px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 120px;
  left: -20px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.tooltip-wrapper .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.tooltip-wrapper .tooltip:after {
  border-left: solid transparent 6px;
  border-right: solid transparent 6px;
  border-top: solid #ffffff 6px;
  top: -6px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
  transform: rotate(180deg);
}

.tooltip-wrapper:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(2px);
  -moz-transform: translateY(2px);
  -ms-transform: translateY(2px);
  -o-transform: translateY(2px);
  transform: translateY(2px);
}

/* IE can just show/hide with no transition */
.lte8 .tooltip-wrapper .tooltip {
  display: none;
}

.lte8 .tooltip-wrapper:hover .tooltip {
  display: block;
}
.tooltip ul {
  padding-left: 0;
  margin-bottom: 0;
}
.tooltip ul li {
  list-style: none;
  font-size: 12px;
  line-height: 16px;
  font-family: "OpenSans-Regular";
  padding: 10px;
}
/* comment due to not need in application detail remove hover */
/* .tooltip ul li:first-child {
  font-family: "OpenSans-Bold";
  color: #2dc3e8;
  background: #ecfbff;
} */
.main_div_image img {
  width: 100%;
}
.application-head h6 {
  font-size: 18px;
  line-height: 25px;
  color: #303030;
  font-family: "OpenSans-SemiBold.ttf";
}

.expand-heads span {
  margin-left: 10px;
}

/* ---------------------Newly Added CSS-------------------------------- */
.collpased-content {
  padding: 20px 16px;
}
.inner-block-flex {
  /* display: flex; */
  justify-content: space-between;
}
.red-block {
  /* margin-top: 20px; */
  /* background: #ff4848; */
  border: 1px solid #ff4848;
  border-radius: 4px;
}

.inner-block-one label,
.inner-block-two label,
.red-block label,
.common-p {
  width: 100%;
  font-size: 12px;
  line-height: 19px;
  /* font-family: "OpenSans-SemiBold.ttf"; */
  color: #606060;
  margin-bottom: 8px;
}
.common-label {
  font-size: 14px;
  line-height: 19px;
  font-family: "OpenSans-SemiBold.ttf";
  color: #606060;
  margin-bottom: 8px;
}
.notice-p {
  font-size: 10px;
  line-height: 14px;
  color: #f9bb04;
  font-family: "OpenSans-SemiBold.ttf";
}
.inner-block-two button {
  background: #ebfff3;
  /* Success */
  border: 0.5px solid #46a96f;
  border-radius: 4px;
}
.selector-item_radio:checked + .selector-item_label.valid {
  background-color: var(--blue);
  color: var(--white);
  /* transform: translateY(-2px); */
  background: #ebfff3;
  border: 1px solid #46a96f;
  border-radius: 4px;
  color: #46a96f;
}
.selector-item_radio:checked + .selector-item_label.invalid {
  background-color: var(--blue);
  color: var(--white);
  /* transform: translateY(-2px); */
  background: #fff8f8;
  border: 1px solid #ffcdcd;
  border-radius: 4px;
  color: #fb9191;
}
.red-block input[type="number"] {
  background: #fff8f8;
  border: 1px solid rgba(255, 133, 133, 0.37);
  border-radius: 4px;
  padding: 5px;
}

.wrapper-tooltip {
  text-transform: uppercase;
  display: flex;
  top: -5px;
  color: #555;
  cursor: help;
  font-family: "Gill Sans", Impact, sans-serif;
  font-size: 20px;
  /* padding: 15px 20px; */
  position: relative;
  text-align: center;
  width: 20px;
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

.wrapper-tooltip .tooltip-custom {
  background: #1496bb;
  font-size: 10px;
  bottom: 100%;
  color: #fff;
  display: block;
  left: -20px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 20px;
  pointer-events: none;
  position: absolute;
  /* width: 100%; */
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.wrapper-tooltip .tooltip-custom:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.wrapper-tooltip .tooltip-custom:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #1496bb 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.wrapper-tooltip:hover .tooltip-custom {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .wrapper-tooltip .tooltip-custom {
  display: none;
}

.lte8 .wrapper-tooltip:hover .tooltip-custom {
  display: block;
}

.subcontent-flex {
  display: flex;
  justify-content: space-between;
}
.subcontent-flex.blue-flex {
  background: #f5fdff;
  border-radius: 8px;
  padding: 16px 25px;
}
.inner-block {
  margin-top: 35px;
}
.blue-btn-c {
  width: 100%;
  margin-left: 12px;
}
.blue-btn-c {
  background: #ffffff;
  /* Primary CTA */
  border: 0.5px solid #2dc3e8;
  border-radius: 4px;
  color: #2dc3e8;
  padding: 10px 24px;
  font-size: 14px;
  line-height: 19px;
}
.blue-btn-c svg {
  margin-right: 12px;
}
.selecotr-item.new-c {
  width: 120px;
  margin: 0;
}
.selecotr-item.new-c:first-child {
  margin-right: 8px;
}
.selector.new-selector {
  width: 100%;
}
.collpase-btn {
  background-color: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  text-align: left;
  background-image: url(../images/dwnwrdarrow.svg);
  background-position: 99% 50%;
  background-repeat: no-repeat;
  padding: 8px 15px;
  border: 0;
  font-size: 14px;
  line-height: 19px;
  font-family: "OpenSans-SemiBold.ttf";
  color: #606060;
  margin-top: 25px;
}
.col-white.new-c-w,
.md-predmsg.cust-md-predmsg {
  width: 100%;
  max-width: 100%;
}
.new-star {
  color: red;
}
.login-radio-types input[type="radio"] {
  display: none;
}
.login-radio-types input[type="radio"]:checked + label {
  color: #606060;
  font-family: "OpenSans-SemiBold.ttf";
}
.login-radio-types label {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 19px;
  color: #606060;
  font-family: "OpenSans-Regular";
  cursor: pointer;
}
.login-radio-types input[type="radio"]:checked + label:before {
  background-color: #2dc3e8;
  border: 2px solid #2dc3e8;
}
.login-radio-types input[type="radio"] + label:before {
  content: "";
  display: inline-block;
  /* width: 22px; */
  /* height: 22px; */
  padding: 3px;
  margin-right: 3px;
  /* background-clip: content-box; */
  border: 2px solid #8e8e8e;
  background-color: #ffffff;
  border-radius: 50%;
}
.wrapper-tooltip.new-wrp-tooltip {
  top: 0;
  margin-left: 10px;
}
.f-b-subwrap {
  display: flex;
  justify-content: space-between;
}
.mr-b5 {
  margin-bottom: 5px;
}
.mr-b10 {
  margin-bottom: 10px;
}
.fb-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.blue-btn-c.blue-new-btn-c {
  width: 30%;
}
.mr-b5 {
  margin-bottom: 5px;
}
.radio-select {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}
.login-radio-types {
  background: #f5fdff;
  border-radius: 8px;
  margin: 0;
  padding: 2px;
}
.radios-wrap {
  padding: 0px 10px;
}
 
.pd-25 {
  padding: 0 25px;
}
.f-b-wrap {
  padding: 15px 50px;
}
.radios-wrap.mr-t20 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin: 0;
}
.new-form-wrap {
  padding: 10px 70px;
}
.radios-wrap.radio-grp {
  padding: 20px;
  margin: 0px 0px 20px;
}
/* ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------- */
@media (max-width: 1200px) {
  .patstb-flex {
    display: block;
    padding: 0;
  }
  .login-radio-types label,
  .blue-btn-c button {
    font-size: 12px;
  }
  .application-head h6,
  .subheaders h6,
  .doc-up-tabs button.active {
    font-size: 13px;
  }
  .application-head .modal-sm button span {
    font-size: 8px;
  }
  .toggle::before,
  .toggle::after,
  .info-block label,
  .basic-info span,
  .status-block span h6,
  .view-b label,
  .toggle-heads button,
  .block.brwn-block label,
  .block.green-block label,
  .block label,
  .error-msg {
    font-size: 12px;
  }
  .toggle::after {
    right: 7px;
  }
  .toggle::before {
    left: -70px;
  }
  .basic-info span,
  .view-b label {
    line-height: 12px;
  }
  .toggle-heads h6,
  .block.brwn-block span,
  .block .green-stat,
  .block .red-stat,
  .block .brown-stat,
  .expand-heads button,
  .block.green-block span,
  .month-inc h6,
  .month-inc span,
  /* .emp-inputs label, */
  .emp-inputs input,
  .emp-inputs select,
  .doc-up-tabs button,
  .app-nav-tabs .nav-link.active .name1 h6,
  .app-nav-tabs .name1 h6,
  .docs-upload .blue-up,
  .emp-paystb-btn span,
  .inner-block-one label,
  .inner-block-two label,
  .red-block label,
  .common-p,
  .common-label,
  .collpase-btn {
    font-size: 10px;
  }
  .main_div_image {
    padding: 10px;
    max-width: 100%;
  }
  .basic-info .basic-subinfo,
  .appl-status-wrap .row .sub-aapl {
    margin-bottom: 14px;
  }
  .basic-info .info-block {
    line-height: 10px;
  }
  .application-head {
    display: block !important;
  }
}
@media (max-width: 991px) {
  .selector-item_label {
    font-size: 10px;
    height: 32px;
    padding: 0 7px;
  }
  .search-block input {
    padding: 9px 10px 9px 20px;
    font-size: 8px;
  }
  .search-block button {
    width: 30px;
    height: 30px;
  }
  .search-block .head-blue {
    font-size: 12px;
    line-height: 5px;
  }
  .f-b-subwrap {
    display: block;
  }
  .fb-inner {
    flex-direction: row;
  }
  .fb-img-wrap {
    display: block !important;
  }
  .doc-subwrap.d-flex.new-doc-subwrap {
    justify-content: space-between;
  }
  .blue-btn-c.blue-new-btn-c {
    width: 40%;
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
  }
  .f-b-wrap {
    padding: 15px 0px;
}
  .blue-btn-c.blue-new-btn-c button{
    width: 200px !important;
    margin: 0 10px 0 0;
  }
  .radios-wrap.mr-t20{
    margin: 0;
  }
  .pd-25 {
    padding: 0 5px;
}
.new-form-wrap {
  padding: 10px 10px;
}
}
@media (max-width: 768px) {
  .inner-block-flex,
  .subcontent-flex {
    display: block;
  }
  .block-b {
    margin-top: 20px;
  }
  .custom-body.cust-sd-body .head-btn {
    display: block !important;
  }
  .md-predmsg span {
    left: 35%;
    top: 5px;
  }
  .app-nav-tabs .nav-link {
    padding: 4px 10px;
  }
  .application-head .modal-sm {
    margin-bottom: 20px;
  }
  .nav-flexes {
    flex-direction: column-reverse;
  }
  .toggleWrapper-wrap {
    margin-bottom: 10px;
  }
  .toggleWrapper {
    left: 70px;
  }
  .toggle::before {
    left: -70px;
    top:0;
  }
  .toggle::after {
    right: 19px;
    top:0;
  }
  .emp-dets {
    padding: 10px;
  }
  .toggle {
    width: 34px;
    height: 18px;
  }
  input:checked + .toggle .toggle__handler {
    transform: translate3d(15px, 0, 0) rotate(0);
  }
  .toggle__handler {
    top: -4px;
    width: 10px;
    height: 10px;
  }
  .toggle-heads h6 {
    padding: 5px 10px;
  }
  .app-nav-tabcontent .tab-pane {
    padding: 10px 8px;
    margin-top: 11px;
  }
  .img-dwd button {
    width: 23px;
    height: 23px;
  }
  .img-dwd {
    margin-bottom: 10px;
  }
  .cust-card-appl {
    /* padding: 10px; */
  }

  .info-block .view-b img {
    margin-bottom: 5px;
  }
  .name1 {
    display: block;
  }
  .progress-gr {
    float: none;
    margin: auto;
  }
  .cancelbtn,
  .modal-blue-btn {
    display: flex;
    justify-content: center;
  }
  .cancelbtn,
  .modal-blue-btn {
    padding: 10px;
    font-size: 10px;
    /* width: 100%; */
    /* margin: 10px 0; */
  }
  .modal-footer {
    display: block;
  }
  .nav-tabs .nav-link {
    max-width: 50%;
    flex: 0 0 50%;
    text-align: center;
  }
  .radios-wrap.mr-t20 {
    margin: 0;
  }
  .radios-wrap {
    padding: 0px 0px;
  }
  .login-radio-types {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .f-b-wrap {
    padding: 15px 0px;
  }
  .f-b-subwrap {
    display: block;
  }
  .fb-inner {
    flex-direction: row;
  }
  .fb-img-wrap {
    display: block !important;
  }
  .blue-btn-c.blue-new-btn-c{
    display: block;
  }
  .blue-btn-c.blue-new-btn-c button{
    width: 100% !important;
    margin: 0 0 20px 0 
  }
}
@media (max-width: 576px) {
  .subcontent-flex.blue-flex {
    padding: 20px 10px;
  }
  .common-label {
    font-size: 12px;
    line-height: 10px;
  }
  .inner-block-one label,
  .inner-block-two label,
  .red-block label,
  .common-p,
  .collpase-btn {
    width: 100%;
    font-size: 12px;
    line-height: 10px;
  }
  .block-b {
    display: block !important;
    text-align: center;
  }
  .red-block input[type="number"] {
    width: 100%;
  }
  .block-b .docs-img {
    margin: auto;
  }
  .blue-btn-c {
    width: 100%;
    margin: 20px 0 0 0;
  }
  .md-predmsg span {
    left: 30%;
    top: 5px;
  }
  .application-head .modal-sm button span {
    line-height: 10px;
  }
  .toggle::before,
  .toggle::after,
  .info-block label,
  .basic-info span,
  .status-block span h6,
  .view-b label,
  .toggle-heads button,
  .block.brwn-block label,
  .block.green-block label,
  .block label,
  .error-msg {
    font-size: 12px;
  }
  .selector-item_label {
    font-size: 10px;
  }
  .emp-inputs {
    margin-bottom: 13px;
  }
  .modal-table thead tr th,
  .modal-table tbody tr td {
    font-size: 9px;
    line-height: 12px;
  }
  span.switcher input {
    width: 135px;
  }
  span.switcher input:checked:before {
    width: 55px;
  }
  span.switcher.switcher-1 input:not(:checked) + label,
  span.switcher.switcher-2 input:not(:checked) + label,
  span.switcher.switcher-3 input:not(:checked) + label {
    right: 15px;
  }
  span.switcher label {
    width: 70px;
  }
  .selector-item_label {
    height: 24px;
  }
  .tb-grey-block,
  .tb-green-block,
  .tb-red-block {
    font-size: 10px;
    top: 12px;
  }

  .docs-img {
    margin-bottom: 10px;
  }
  .toggle-heads h6,
  .block.brwn-block span,
  .block .green-stat,
  .block .red-stat,
  .block .brown-stat,
  .expand-heads button,
  .block.green-block span,
  .month-inc h6,
  .month-inc span,
  /* .emp-inputs label, */
  .emp-inputs input,
  .emp-inputs select,
  .doc-up-tabs button,
  .app-nav-tabs .nav-link.active .name1 h6,
  .app-nav-tabs .name1 h6,
  .docs-upload .blue-up,
  .emp-paystb-btn span {
    font-size: 12px;
  }
  .application-head h6,
  .subheaders h6,
  .doc-up-tabs button.active {
    font-size: 10px;
  }
  .basic-info {
    margin-bottom: 0;
  }
  .basic-info .row.mr-b20 {
    margin-bottom: 0;
  }
  .row.doc-subwrap {
    display: flex;
    justify-content: center;
  }
  .prf-upload {
    font-size: 7px;
    line-height: 10px;
  }
}
@media (max-width: 480px) {
  .selector {
    width: 90%;
  }
  .modal-header h5 {
    font-size: 10px;
  }
  .custom-body.cust-sd-body .head-btn {
    display: block !important;
  }
  .application-head h6,
  .subheaders h6,
  .doc-up-tabs button.active {
    font-size: 12px;
  }
  .emp-paystb-btn {
    padding: 10px 5px;
  }
  .emp-paystb-btn svg {
    margin-right: 2px;
    width: 8px;
  }
  .doc-up-tabs button,
  .doc-up-tabs button.active {
    line-height: 10px;
    padding: 10px;
  }
  .doc-subwrap.d-flex.new-doc-subwrap{
    display: block !important;
  }
  .doc-subwrap.d-flex.new-doc-subwrap .docs-img img,
  .doc-subwrap.d-flex.new-doc-subwrap .docs-img{
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 380px) {
  .toggle-heads h6,
  .block.brwn-block span,
  .block .green-stat,
  .block .red-stat,
  .block .brown-stat,
  .expand-heads button,
  .block.green-block span,
  .month-inc h6,
  .month-inc span,
  /* .emp-inputs label, */
  .emp-inputs input,
  .emp-inputs select,
  .doc-up-tabs button,
  .app-nav-tabs .nav-link.active .name1 h6,
  .app-nav-tabs .name1 h6,
  .docs-upload .blue-up,
  .emp-paystb-btn span {
    font-size: 6px;
  }
  .md-predmsg span {
    left: 22%;
    top: 5px;
  }
  .blue-btn-c.blue-new-btn-c button{
    padding: 10px 5px;
  }
}
.blue-btn-d {
  background: #ffffff;
  /* Primary CTA */
  border: 0.5px solid #2dc3e8;
  border-radius: 4px;
  color: #2dc3e8;
  padding: 15px 15px;
  font-size: 14px;
  line-height: 14px;
}
.highlight-text-background {
  color: #000000 !important;
  font-size: 12px;
  background-color: yellow !important;
  display: inline-flex;
}
.highlight-text-background-small {
  color: #000000 !important;
  font-size: 11px;
  background-color: yellow !important;
  display: inline-flex;
}
.footer-custom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 55px;
  background-color:#F5FDFF;
  padding:10px 20px;
}

.component_title {
  font-size: 12px;
  color: #606060;
  font-family: "OpenSans-SemiBold";
}

.lable_title {
  font-size: 11px;
}

.form-label{
  margin-bottom: 0;
}